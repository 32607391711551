<template>
	<v-dialog v-resize="resizeScrollableCard"
			  ref="dialog"
			  :persistent="persistent"
			  :value="value"
			  @input="$emit('update:value', $event)"
			  @keydown.esc="$emit('update:value', false)"
			  :max-width="maxWidth"
			  :hide-overlay="hideOverlay"
			  content-class="overflow-hidden"
			  :content-class="contentClass"
			  style="z-index: 5000">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs"></slot>
		</template>
		
		<v-card class="d-flex align-start flex-wrap overflow-hidden" elevation="0">
			<v-container ref="dialogCard" fluid class="px-6 py-0 overflow-y-auto frp-scrollbar">
				<slot name="prepend"></slot>
				<v-row class="my-0 white px-3 d-flex align-center" style="position: sticky; top: 0; z-index: 100">
					<v-col class="px-0">
						<p class="text-h6 blue--text text--darken-4 mt-3 d-flex"
						   :class="{'justify-center': titleCenter, 'justify-space-between': !titleCenter, 'mb-1': $scopedSlots.subtitle}">
							<slot name="title">
								{{ title }}
							</slot>
						</p>
						<slot name="subtitle"></slot>
					</v-col>
					<slot name="close"></slot>
				</v-row>
				
				<v-row class="my-0">
					<v-col class="py-0">
						<slot :onIntersect="resizeScrollableCard" name="content"></slot>
					</v-col>
				</v-row>
				
				<v-row class="my-0 white" style="position: sticky; bottom: 0">
					<v-col>
						<v-responsive content-class="d-flex justify-end py-3">
							<slot name="footer"></slot>
						</v-responsive>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		hideOverlay: Boolean,
		title: String,
		contentClass: String,
		titleCenter: {
			type: Boolean,
			default: false
		},
		persistent: Boolean,
		maxWidth: {
			default: 640
		}
	},
	methods: {
		resizeScrollableCard() {
			if(this.$refs.dialogCard) {
				this.$refs.dialogCard.style.maxHeight = "100%";
				const height = this.$refs.dialog.$refs.dialog.offsetHeight;
				
				if(height)
					this.$refs.dialogCard.style.maxHeight = `${height}px`;
			}
		}
	}
};
</script>
