<template>
	<frp-form-card v-if="isInitialized && isCreateMode">
		<v-form :readonly="isFormReadonly" :disabled="isFormDisabled || (isSnilsEmpty)" :ref="refs.form"
				v-model="formValid">
			<frp-form-card-blocks>
				<frp-form-card-block :title="isCreateMode ? $t('common.uploadTrustHint.title') : $t('titles.general')">
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" class="pb-0 mt-0 d-flex flex-column body-2" style="row-gap: 12px">
							<span>{{ $t("common.uploadTrustHint.part1") }}</span>
							<span>{{ $t("common.uploadTrustHint.part2") }}</span>
							<div>
								<span>{{ $t("common.uploadTrustHint.part3") }}</span>
								<frp-link-btn classes="d-inline-flex mr-1" :href="trustRegistryLink">
									{{ $t("common.uploadTrustHint.part4") }}
								</frp-link-btn>
								<span>{{ $t("common.uploadTrustHint.part5") }}</span>
							</div>
							<span>{{ $t("common.uploadTrustHint.part6") }}</span>
							<span>{{ $t("common.uploadTrustHint.part7") }}</span>
							<div class="error--text font-weight-bold">
								<span>{{ $t("common.uploadTrustHint.part8") }}</span>
								<frp-link-btn classes="d-inline-flex" style="color: inherit; font-weight: inherit"
											  :href="leasingTrustDelegationOfAuthorityInstructionLink">
									{{ $t("common.uploadTrustHint.part9") }}
								</frp-link-btn>
							</div>
							<span>{{ $t("common.uploadTrustHint.part10") }}</span>
							<div>
								<span>{{ $t("common.uploadTrustHint.part11") }}</span>
								<frp-link-btn classes="d-inline-flex" :href="trustRegistryLink">
									{{ $t("common.uploadTrustHint.part12") }}
								</frp-link-btn>
								<span>{{ $t("common.uploadTrustHint.part13") }}</span>
							</div>
							<span>{{ $t("common.uploadTrustHint.part14") }}</span>
							<span>{{ $t("common.uploadTrustHint.part15") }}</span>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
					
					<frp-form-card-block-row>
						<frp-form-card-block-col>
							<frp-btn height="40"
									 elevation="0"
									 class="px-4"
									 target="_blank"
									 :href="trustInstructionLink">
								{{ $t("buttons.downloadTrustInstruction") }}
							</frp-btn>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
					
					<frp-form-card-block-row class="mt-5">
						<frp-form-card-block-col cols="12" class="pb-0 mt-0">
							<frp-alert v-if="isSnilsEmpty"
									   class="mb-2"
									   type="error"
									   custom-background-color="white"
									   color-class="secondary--text"
									   style="width: fit-content">
								<template>
									{{ $t("alerts.info.inputSnilsOnAddTrust.onAddTrust") }}
									<router-link
										:to="{ name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL,
											   params: { id: currentActiveTrustId, employeeId: $route.params.employeeId }}"
										class="mx-1 secondary--text">
										{{ $t("alerts.info.inputSnilsOnAddTrust.inputSnils") }}
									</router-link>
								</template>
							</frp-alert>
							<frp-alert v-if="currentActiveTrustId" class="mb-2"
									   type="warning"
									   style="width: fit-content">
								{{ $t("alerts.errors.reloadTrust.ifReload") }}&nbsp;
								<router-link :to="{
									name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST,
									params: { id: currentActiveTrustId, employeeId: $route.params.employeeId }
								}" class="white--text" target="_blank">
									<span>{{ $t("alerts.errors.reloadTrust.current") }}</span>
								</router-link>
								&nbsp;{{ $t("alerts.errors.reloadTrust.revokeTrust") }}
							</frp-alert>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
					
					<frp-form-card-block-row>
						<frp-form-card-block-col>
							<frp-form-card-block-row>
								<frp-form-card-block-col cols="12" sm="6" md="5" class="pb-0">
									<frp-form-item
										v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ])">
										<frp-file-field
											:disabled="isSnilsEmpty"
											:label="$t('fields.trust.label')"
											v-if="!dataUrl"
											:accept="['application/xml']"
											required
											v-model="trustFile"
											persistent-hint
											@onload="handleOnload"
											:meta="trustFileMeta"
											@update:value="handleTrustFileUpload"
											:placeholder="$t('fields.trust.placeholder')">
										</frp-file-field>
										<div v-else class="d-flex">
											<frp-file deletable
													  :name="$t('fields.trust.label')"
													  :label="$t('fields.trust.label')"
													  required
													  @delete="handleTrustFileDelete"
													  :uploading="trustFileMetaIsLoading"
													  class="mb-2 mr-6">
											</frp-file>
										</div>
									</frp-form-item>
								</frp-form-card-block-col>
							</frp-form-card-block-row>
							<frp-form-card-block-row>
								<frp-form-card-block-col cols="12" sm="6" md="5" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
									<frp-form-item>
										<frp-date-field
											:disabled="isSnilsEmpty"
											is-required
											required
											:label="$t('fields.validUntilDate.label')"
											v-model="expireAt"
											:min="Date.now()"
											:placeholder="$t('fields.validUntilDate.placeholder')"/>
									</frp-form-item>
								</frp-form-card-block-col>
								
								<frp-form-card-block-col offset-md="2" cols="12" sm="6" md="4"
														 :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
								</frp-form-card-block-col>
							</frp-form-card-block-row>
						</frp-form-card-block-col>
						<frp-form-card-block-col sm="6" md="5" class="d-flex flex-column justify-center align-end mb-3">
						</frp-form-card-block-col>
					</frp-form-card-block-row>
					
					<frp-form-card-block-row>
						<frp-form-card-block-col cols="12" class="pb-0 mt-0 d-flex justify-end mr-1 pb-7">
							<frp-btn elevation="0"
									 @click="submit"
									 :loading="isFormSaving"
									 :disabled="!isFormValid">
								{{ $t("buttons.sendToReview") }}
							</frp-btn>
						</frp-form-card-block-col>
					</frp-form-card-block-row>
				</frp-form-card-block>
			</frp-form-card-blocks>
		</v-form>
	</frp-form-card>
	
	<v-card v-else-if="isInitialized && !isCreateMode"
			class="pa-8 pb-1 d-flex align-start flex-wrap"
			elevation="0">
		<v-row>
			<frp-details-group>
				<frp-details-section-title>{{ $t("details.titles.general") }}</frp-details-section-title>
				<template v-if="!details.isSigned">
					<v-row>
						<v-col class="mr-6" cols="auto">
							<frp-file :label="$t('fields.trust.label')" :id="trust.fileId" class="mb-2"></frp-file>
						</v-col>
						<v-col cols="auto">
							<frp-alert style="width: fit-content" type="warning"
									   :text="$t('details.isSigned.digitallyMissed')"/>
						</v-col>
					</v-row>
				</template>
				<template v-else>
					<frp-file :label="$t('fields.trust.label')" :id="trust.fileId" class="mb-2"></frp-file>
					<div class="mb-2">
						<v-subheader class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
							<span>{{ $t("fields.signature.label") }}</span>
						</v-subheader>
						<span @click="certificateInfoDialog = true" class="pointer text-body-2 py-2 secondary--text">
								{{ $t("aliases.documents.signatureGeneral") }}
						</span>
					</div>
					
					<frp-dialog v-model="certificateInfoDialog" :title="$t('titles.signatureGeneral')" maxWidth="420">
						<template #close>
							<div class="pointer d-flex align-center secondary--text"
								 @click="certificateInfoDialog = false">
								<v-icon :color="colors.secondary.base" class="mr-1 mdi-18px">mdi-close</v-icon>
								<span>{{ $t("buttons.close") }}</span>
							</div>
						</template>
						
						<template #content>
							<frp-details-item
								:title="$t('details.titles.owner')"
								:value="certificateInfo.owner"/>
							<frp-details-item
								:title="$t('details.titles.certificate')"
								:value="certificateInfo.thumbprint"/>
							<frp-details-item
								:title="$t('details.titles.expireDate')"
								:value="certificateInfo.expireDate"/>
						</template>
					</frp-dialog>
				</template>
				
				<frp-details-item :title="$t('details.expireDate')"
								  :value="details.expireDate"/>
				
				<template v-if="TrustStatusType.ACCEPTED === trust.status">
					<frp-details-item
						:title="$t('details.acceptedDate')"
						:value="details.acceptedDate"/>
					<frp-details-item :title="$t('details.acceptedEmployeeName')"
									  :value="details.acceptedEmployeeName"/>
				</template>
				
				<template v-else-if="TrustStatusType.DECLINED === trust.status">
					<frp-details-item
						:title="$t('details.declinedDate')"
						:value="details.declinedDate"/>
					<frp-details-item :title="$t('details.declinedEmployeeName')"
									  :value="details.declinedEmployeeName"/>
				</template>
				
				<template v-else-if="TrustStatusType.CREATED === trust.status">
					<frp-details-item
						:title="$t('details.canceledDate')"
						:value="details.canceledDate"/>
					<frp-details-item :title="$t('details.canceledEmployeeName')"
									  :value="details.canceledEmployeeName"/>
				</template>
				
				<template v-if="acceptorFullname">
					<frp-details-item
						:title="$t('details.titles.acceptor')"
						:value="acceptorFullname"/>
				</template>
				
				<template v-if="cancellerFullname">
					<frp-details-item
						:title="$t('details.titles.canceller')"
						:value="cancellerFullname"/>
				</template>
				
				<template v-if="declinerFullname">
					<frp-details-item
						:title="$t('details.titles.decliner')"
						:value="declinerFullname"/>
				</template>
				
				<template v-if="trustDeclineReason">
					<frp-details-item
						:title="$t('details.titles.declineReason')"
						:value="trustDeclineReason"/>
				</template>
			</frp-details-group>
		</v-row>
		
		<frp-approving-actions :accept-permissions="Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_ACCEPT"
							   :decline-permissions="trust.status === TrustStatusType.ACCEPTED
							   		? Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CANCEL
							   		: Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_DECLINE"
							   @approve="acceptTrust"
							   @reject="trust.status === TrustStatusType.ACCEPTED ? cancelTrustAccept() : isDeclineTrustDialogOpened = true"
							   :reject-btn-text="trust.status === TrustStatusType.ACCEPTED ? $t('buttons.declineTrust') : $t('buttons.cancelTrust')"
							   :is-approving="isAccepting"
							   :is-rejecting="isDeclining"
							   :is-approve-disabled="trust.status === TrustStatusType.ACCEPTED || trust.status === TrustStatusType.DECLINED"
							   :is-reject-disabled="trust.status === TrustStatusType.DECLINED">
		</frp-approving-actions>
		
		<frp-dialog v-model="isDeclineTrustDialogOpened" :title="$t('dialogs.declineTrust.title')" persistent>
			<template #content>
				<v-form ref="declineTrustForm" v-model="isDeclineTrustFormValid">
					<frp-textarea :label="$t('fields.reason.label')"
								  autocomplete="off"
								  required
								  :placeholder="$t('fields.reason.placeholder')"
								  outlined
								  counter="250"
								  :rules="validation.declineReason"
								  v-model="declineReason">
					</frp-textarea>
				</v-form>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 color="primary"
						 @click="handleCloseDeclineTrustDialog">
					{{ $t("buttons.back") }}
				</frp-btn>
				<frp-btn class="elevation-0"
						 color="secondary"
						 :loading="isDeclining"
						 :disabled="!isDeclineTrustFormValid"
						 @click="handleDeclineTrust">
					{{ $t("buttons.confirm") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</v-card>
	
	<frp-counterparty-employee-trust-loader v-else></frp-counterparty-employee-trust-loader>
</template>

<script>
import FrpAlert from "@/components/alerts/FrpAlert";
import FrpBtn from "@/components/buttons/FrpBtn";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpDialog from "@/components/dialogs/FrpDialog";
import FrpIcon from "@/components/icon/FrpIcon";
import { externalUrls } from "@/constants/olk/externalUrls";
import { RouteNames } from "@/router/routes";
import { prepareMaxLengthRule } from "@/utils/validation";
import FrpActions from "Components/buttons/FrpActions";
import FrpDigitalSignatureDialog from "Components/digitalSignature/FrpDigitalSignatureDialog";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpDateField from "Components/fields/FrpDateField";
import FrpFileField from "Components/fields/FrpFileField";
import FrpTextField from "Components/fields/FrpTextField";
import FrpFormActions from "Components/form/FrpFormActions";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import FrpFormCard from "Components/markup/FrpFormCard";
import FrpFormCardBlock from "Components/markup/FrpFormCardBlock";
import FrpFormCardBlockCol from "Components/markup/FrpFormCardBlockCol";
import FrpFormCardBlockRow from "Components/markup/FrpFormCardBlockRow";
import FrpFormCardBlocks from "Components/markup/FrpFormCardBlocks";
import FrpFormItem from "Components/markup/FrpFormItem";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpApprovingActions from "Components/buttons/FrpApprovingActions";
import FrpDetailsSectionColumn from "Components/details/FrpDetailsSectionColumn";
import FrpDetailsItem from "Components/details/FrpDetailsItem";
import FrpDetailsGroup from "Components/details/FrpDetailsGroup";
import FrpDetailsSectionTitle from "Components/details/FrpDetailsSectionTitle";
import authorizationMixin from "Mixins/authorizationMixin";
import storeManager from "Store/manager";
import FrpCounterpartyEmployeeTrustLoader from "Views/counterpartyEmployee/FrpCounterpartyEmployeeTrustLoader";
import FrpPhoneField from "Components/fields/FrpPhoneField";
import FrpFile from "Components/common/FrpFile";
import colorsMixin from "Mixins/colorsMixin";
import formMixin from "Mixins/formMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { actionTypes, mutationTypes, getterTypes } from "Store/modules/counterpartyEmployee/modules/trust/types";
import { createNamespacedHelpers } from "vuex";
import { masks } from "Constants/masks";
import { TrustStatusType } from "Types/TrustStatusType";

const namespace = storeManager.counterpartyEmployee.trust.namespace;
const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			TrustStatusType,
			inn: "",
			namespace,
			RouteNames,
			trustFile: null,
			masks,
			dataUrl: "",
			validation: {
				declineReason: [prepareMaxLengthRule({ maxLength: 250 })]
			},
			signatureDialog: false,
			certificateInfoDialog: false,
			isDeclineTrustDialogOpened: false,
			isDeclineTrustFormValid: false,
			trustInstructionLink: process.env.VUE_APP_OLK_TRUST_INSTRUCTION_LINK,
			trustRegistryLink: externalUrls.trustRegistryLink,
			leasingTrustDelegationOfAuthorityInstructionLink: externalUrls.leasingTrustDelegationOfAuthorityInstructionLink
		};
	},
	computed: {
		...mapState({
			state: state => state,
			trust: state => state.trust,
			trustFileMeta: state => state.trustFileMeta,
			trustFileMetaIsLoading: state => state.trustFileMetaIsLoading,
			isInitialized: state => state.isInitialized,
			isAccepting: state => state.isAccepting,
			isDeclining: state => state.isDeclining,
			isCancelling: state => state.isCancelling,
			currentActiveTrustId: state => state.currentActiveTrustId,
			certificateInfo: state => state.certificateInfo,
			trustDeclineReason: state => state.trust.declineReason,
			acceptorFullname: state => state.trustActioners.acceptorFullname,
			cancellerFullname: state => state.trustActioners.cancellerFullname,
			declinerFullname: state => state.trustActioners.declinerFullname
		}),
		...mapGetters({
			details: getterTypes.details,
			isSnilsEmpty: getterTypes.isSnilsEmpty
		}),
		expireAt: {
			get() {
				return this.trust.expireAt;
			},
			set(value) {
				this.setExpireAt(value && value.getTime());
			}
		},
		declineReason: {
			get() {
				return this.state.declineReason;
			},
			set(value) {
				this.setDeclineReason(value);
			}
		}
	},
	methods: {
		handleTrustFileDelete() {
			this.dataUrl = "";
			this.removeTrustFile();
		},
		...mapActions({
			handleTrustFileUpload: actionTypes.handleTrustFileUpload,
			save: actionTypes.save,
			acceptTrust: actionTypes.acceptTrust,
			declineTrust: actionTypes.declineTrust,
			cancelTrustAccept: actionTypes.cancelTrustAccept,
			removeTrustFile: actionTypes.removeTrustFile
		}),
		...mapMutations({
			setExpireAt: mutationTypes.SET_TRUST_EXPIRE_AT,
			resetDeclineReason: mutationTypes.RESET_DECLINE_REASON,
			setDeclineReason: mutationTypes.SET_DECLINE_REASON
		}),
		handleOnload(dataUrl) {
			this.dataUrl = dataUrl;
		},
		handleCloseDeclineTrustDialog() {
			this.$refs.declineTrustForm.resetValidation();
			this.resetDeclineReason();
			this.isDeclineTrustDialogOpened = false;
		},
		async handleDeclineTrust() {
			await this.declineTrust();
			this.handleCloseDeclineTrustDialog();
		}
	},
	created() {
		this.initializeStore({
			id: this.$route.params.id,
			counterpartyId: this.$route.params.counterpartyId,
			employeeId: this.$route.params.employeeId
		});
	},
	components: {
		FrpLinkBtn,
		FrpActions,
		FrpIcon,
		FrpDialog,
		FrpAlert,
		FrpBtn,
		FrpDigitalSignatureDialog,
		FrpDateField,
		FrpFileField,
		FrpCheckbox,
		FrpFormActions,
		FrpTextField,
		FrpFormItem,
		FrpFormCardBlockCol,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormCardBlocks,
		FrpFormCard,
		FrpBottomSpace,
		FrpCounterpartyEmployeeTrustLoader,
		FrpTextarea,
		FrpPhoneField,
		FrpFile,
		FrpApprovingActions,
		FrpDetailsSectionColumn,
		FrpDetailsItem,
		FrpDetailsGroup,
		FrpDetailsSectionTitle
	}
};
</script>

<style scoped>
.trust-template {
	background-color: var(--v-white-darken1) !important;
}

.pointer {
	cursor: pointer;
}
</style>
