import { TrustStatusType } from "@/types/TrustStatusType";

export default class ApiCounterpartyEmployeeTrust {
	counterpartyId: string;
	trusteeEmployeeId: string;
	trusteeSnils: string;
	trusteeInn: string;
	expireAt: string;
	fileId: string;
	isSigned: boolean;
	acceptedAt: string;
	acceptorOfficeEmployeeId: string;
	declinedAt: string;
	declinerOfficeEmployeeId: string;
	status: TrustStatusType;
	canceledAt: string;
	cancellerOfficeEmployeeId: string;
	cancellerCounterpartyEmployeeId: string;
	declineReason: string;

	constructor(counterpartyId: string,
		trusteeEmployeeId: string,
		trusteeSnils: string,
		trusteeInn: string,
		expireAt: string,
		fileId: string,
		isSigned: boolean,
		acceptedAt: string,
		acceptorOfficeEmployeeId: string,
		declinedAt: string,
		declinerOfficeEmployeeId: string,
		status: TrustStatusType,
		canceledAt: string,
		cancellerOfficeEmployeeId: string,
		cancellerCounterpartyEmployeeId: string,
		declineReason: string)
	{
		this.counterpartyId = counterpartyId;
		this.trusteeEmployeeId = trusteeEmployeeId;
		this.trusteeSnils = trusteeSnils;
		this.trusteeInn = trusteeInn;
		this.expireAt = expireAt;
		this.fileId = fileId;
		this.isSigned = isSigned;
		this.acceptedAt = acceptedAt;
		this.acceptorOfficeEmployeeId = acceptorOfficeEmployeeId;
		this.declinedAt = declinedAt;
		this.declinerOfficeEmployeeId = declinerOfficeEmployeeId;
		this.status = status;
		this.canceledAt = canceledAt;
		this.cancellerOfficeEmployeeId = cancellerOfficeEmployeeId;
		this.cancellerCounterpartyEmployeeId = cancellerCounterpartyEmployeeId;
		this.declineReason = declineReason;
	}
}
