import { render, staticRenderFns } from "./FrpLegalPersonGeneral.vue?vue&type=template&id=79209408&scoped=true"
import script from "./FrpLegalPersonGeneral.vue?vue&type=script&lang=js"
export * from "./FrpLegalPersonGeneral.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79209408",
  null
  
)

export default component.exports