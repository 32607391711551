<template>
	<div>
		<div class="d-flex">
			<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption d-flex justify-space-between"
						 style="height: fit-content; width: 100%">
				<span>{{ `${label}${required ? "*" : ""}` }}</span>
				<template v-if="tooltipText">
					<frp-information-menu :text="tooltipText"/>
				</template>
			</v-subheader>
		</div>
		<v-text-field outlined
					  @keydown.enter.prevent
					  :placeholder="placeholder"
					  :readonly="readonly"
					  @click:append="$emit('append')"
					  :loading="loading"
					  ref="input"
					  :background-color="colors.white.base"
					  v-model="internalValue"
					  :type="type"
					  :error="error"
					  :error-messages="errors"
					  v-mask="mask"
					  :rules="[...defaultRules, ...rules]"
					  :autocomplete="autocomplete"
					  :name="name"
					  v-on="$listeners"
					  dense
					  :height="height"
					  hide-spin-buttons
					  :append-icon="appendIcon"
					  v-bind="$attrs"
					  class="frp-text-field text-body-2"
					  :class="{ 'frp-dense-field': dense }"
					  :disabled="disabled"
					  :hide-details="hideDetails">
			<template #append-outer v-if="$slots['append-outer']">
				<slot name="append-outer"></slot>
			</template>
			<template #append v-if="$slots['append'] || icon">
				<slot name="append">
					<frp-icon class="mt-1" v-if="icon" :color="iconColor" :src="icon"></frp-icon>
				</slot>
			</template>
		</v-text-field>
	</div>
</template>

<script>
import FrpInformationMenu from "@/components/common/FrpInformationMenu.vue";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { mask, unmask } from "Utils/formatting";
import { requiredRule } from "Utils/validation";

export default {
	inheritAttrs: false,
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		placeholder: String,
		loading: Boolean,
		error: Boolean,
		errors: {
			type: [Array, String],
			default: () => []
		},
		rules: {
			type: Array,
			default: () => []
		},
		readonly: Boolean,
		value: [String, Number],
		height: {
			type: [String, Number],
			default: undefined
		},
		type: String,
		label: String,
		iconColor: String,
		disabled: Boolean,
		clearable: Boolean,
		hideDetails: Boolean,
		dense: Boolean,
		required: Boolean,
		icon: String,
		appendIcon: String,
		mask: String,
		autocomplete: String,
		name: String,
		tooltipText: String
	},
	data() {
		return {
			internalValue: ""
		};
	},
	computed: {
		defaultRules() {
			if(this.required)
				return [requiredRule()];
			else
				return [];
		}
	},
	watch: {
		internalValue: {
			async handler(value) {
				if(this.mask) {
					const unmasked = unmask(value, this.mask);
					
					if(unmasked !== this.value) {
						this.$emit("update:value", unmasked);
					}
				} else {
					if(value !== this.value)
						this.$emit("update:value", value);
				}
			}
		},
		value: {
			async handler(value) {
				await this.$nextTick();
				this.internalValue = this.mask ? mask(value, this.mask) : value;
			},
			immediate: true
		}
	},
	components: {
		FrpInformationMenu,
		FrpIcon
	}
};
</script>
