import { TrustStatusType } from "@/types/TrustStatusType";

export default class Trust {
	id: string;
	status: TrustStatusType;
	expireAt: number;
	createdAt: number;
	isSigned: boolean;
	acceptedAt: number;
	declinedAt: number;

	constructor(
		id: string,
		status: TrustStatusType,
		expireAt: number,
		createdAt: number,
		isSigned: boolean,
		acceptedAt: number,
		declinedAt: number
	)
	{
		this.id = id;
		this.status = status;
		this.expireAt = expireAt;
		this.createdAt = createdAt;
		this.isSigned = isSigned;
		this.acceptedAt = acceptedAt;
		this.declinedAt = declinedAt;
	}
}
