export default class ApiCounterpartyEmployeeTrustCreateRequest {
	counterpartyId: string;
	trusteeEmployeeId: string;
	trusteeSnils: string;
	expireAt: string;
	tempFileId: string;

	constructor(counterpartyId: string,
		trusteeEmployeeId: string,
		trusteeSnils: string,
		expireAt: string,
		tempFileId: string)
	{
		this.counterpartyId = counterpartyId;
		this.trusteeEmployeeId = trusteeEmployeeId;
		this.trusteeSnils = trusteeSnils;
		this.expireAt = expireAt;
		this.tempFileId = tempFileId;
	}
}
