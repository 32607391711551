import { TrustStatusType } from "@/types/TrustStatusType";

export default class CounterpartyEmployeeTrust {
	expireAt: number;
	tempFileId: string;
	fileId: string;
	isSigned: boolean;
	acceptedAt: number;
	acceptorOfficeEmployeeId: string;
	declinedAt: number;
	declinerOfficeEmployeeId: string;
	status: TrustStatusType;
	canceledAt: number;
	cancellerOfficeEmployeeId: string;
	declineReason: string;

	constructor(
		expireAt: number = 0,
		tempFileId: string = "",
		fileId: string = "",
		isSigned: boolean = false,
		acceptedAt: number = 0,
		acceptorOfficeEmployeeId: string = "",
		declinedAt: number = 0,
		declinerOfficeEmployeeId: string = "",
		status: TrustStatusType = TrustStatusType.UNDEFINED,
		canceledAt: number = 0,
		cancellerOfficeEmployeeId: string = "",
		declineReason: string = ""
	)
	{
		this.expireAt = expireAt;
		this.tempFileId = tempFileId;
		this.fileId = fileId;
		this.isSigned = isSigned;
		this.acceptedAt = acceptedAt;
		this.acceptorOfficeEmployeeId = acceptorOfficeEmployeeId;
		this.declinedAt = declinedAt;
		this.declinerOfficeEmployeeId = declinerOfficeEmployeeId;
		this.status = status;
		this.canceledAt = canceledAt;
		this.cancellerOfficeEmployeeId = cancellerOfficeEmployeeId;
		this.declineReason = declineReason;
	}
}
