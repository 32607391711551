<template>
	<frp-internal-list-layout>
		<template #title>
			{{ $t("titles.trusts") }}
		</template>

		<template #side-btn>
			<frp-btn v-if="can([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_CREATE])"
					 class="frp-xs-full-width"
					 @click="handleCreateItem">
				{{ $t("buttons.addTrust") }}
			</frp-btn>
		</template>

		<template #content>
			<v-data-table :headers="headers"
						  :items="items"
						  :loading-text="$t('tables.loading')"
						  hide-default-footer
						  :loading="isItemsLoading"
						  item-key="id"
						  :mobile-breakpoint="mobileBreakpoint"
						  must-sort
						  :options="options"
						  @click:row="handleItemSelect"
						  :item-class="() => 'text-body-2'"
						  class="frp-table clickable-rows elevation-0 mt-2 d-flex flex-column">
				<template #item.expireAt="{ item }">
					<span>{{ formatDate(item.expireAt, dateFormat) }}</span>
				</template>
				<template #item.createdAt="{ item }">
					<span>{{ formatDate(item.createdAt, dateFormat) }}</span>
				</template>
				<template #item.acceptDate="{ item }">
					<span>{{ formatDate(item.acceptDate, dateFormat) }}</span>
				</template>
				<template #item.status="{ item }">
					<frp-trust-status-label :value="item.status"></frp-trust-status-label>
				</template>
				<template #item.isSigned="{ item }">
					<span class="green--text text-subtitle-1" v-if="item.isSigned">{{ $t("aliases.itemAvailable.available") }}</span>
					<span class="grey--text text-subtitle-1" v-else>{{ $t("aliases.itemAvailable.notAvailable") }}</span>
				</template>
			</v-data-table>
		</template>
	</frp-internal-list-layout>
</template>

<script>
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/routes";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpTrustStatusLabel from "Components/common/FrpTrustStatusLabel";
import authorizationMixin from "Mixins/authorizationMixin";
import storeManager from "Store/manager";
import { mutationTypes, actionTypes, getterTypes } from "Store/modules/counterpartyEmployee/modules/trusts/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import FrpTextField from "Components/fields/FrpTextField";
import FrpStatusLabel from "Components/common/FrpStatusLabel";
import FrpInternalListLayout from "Components/layouts/FrpInternalListLayout";

const namespace = storeManager.counterpartyEmployee.trusts.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			formatDate,
			dateFormat,
			headers: [
				{
					text: this.$t("tables.expireAt"),
					value: "expireAt",
					class: "text-caption",
					width: "20%"
				},
				{
					text: this.$t("tables.isSigned"),
					value: "isSigned",
					class: "text-caption",
					width: "20%"
				},
				{
					text: this.$t("tables.acceptDate"),
					value: "acceptDate",
					class: "text-caption",
					width: "20%"
				},
				{
					text: this.$t("tables.status"),
					value: "status",
					class: "text-caption",
					width: "20%"
				},
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "20%"
				}
			]
		};
	},
	computed: {
		...mapGetters({
			items: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		})
	},
	methods: {
		handleItemSelect({ id }) {
			this.$router.push({
				name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST,
				params: { id, employeeId: this.$route.params.employeeId }
			});
		},
		handleCreateItem() {
			this.$router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE });
		}
	},
	created() {
		this.initializeStore({ id: this.$route.params.counterpartyId });
	},
	components: {
		FrpTrustStatusLabel,
		FrpInternalListLayout,
		FrpTextField,
		FrpBtn,
		FrpStatusLabel
	}
};
</script>
