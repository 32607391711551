<template>
	<v-dialog v-model="isDialogOpened" :width="dialogWidth">
		<template v-slot:activator="{ on }">
			<div>
				<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
					<span>{{ label }}</span>
					<span v-if="required">*</span>
				</v-subheader>
				<v-text-field :rules="readonly ? [] : [...rules, ...defaultRules]"
							  :readonly="readonly"
							  :disabled="disabled"
							  :clearable="clearable"
							  :style="{ width }"
							  dense
							  ref="input"
							  :hide-details="hideDetails"
							  :hint="hint"
							  autocomplete="off"
							  v-mask="masks.date"
							  :placeholder="placeholder"
							  @change="onChange"
							  class="frp-field frp-date-field"
							  :class="{ 'frp-dense-field': dense, 'bar-date-field': barDateField }"
							  v-model="internalFormattedRowValue"
							  outlined>
					<template v-slot:append>
						<frp-icon src="ico_calendar"
								  :color="colors.grey.base"
								  @click="!readonly && !disabled && openDialog()">
						</frp-icon>
					</template>
				</v-text-field>
			</div>
		</template>
		<v-date-picker
			class="ma-0 pa-0"
			first-day-of-week="1"
			:max="vuetifyFormattedMaxRowDate"
			:min="vuetifyFormattedMinRowDate"
			@input="closeDialog"
			v-model="internalVuetifyFormattedRowDate"
			full-width>
		</v-date-picker>
	</v-dialog>
</template>

<script>
import { removeTime, setMaxTime } from "@/utils/dates";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import { isMyDateValidDateFormat, isoDateFormat, dateFormat } from "Utils/formats";
import { format, parse, isSameDay } from "date-fns";
import validator from "is-my-date-valid";
import { maxLengths, prepareMaxDateRule, prepareMinDateRule, requiredRule } from "Utils/validation";
import { masks } from "Constants/masks";

const validate = validator({ format: isMyDateValidDateFormat });

export default {
	mixins: [colorsMixin],
	components: { FrpIcon },
	props: {
		disabled: Boolean,
		placeholder: {
			type: String,
			default: "Введите дату"
		},
		rules: {
			type: Array,
			default: () => []
		},
		label: String,
		hint: String,
		readonly: Boolean,
		clearable: Boolean,
		required: Boolean,
		hideDetails: Boolean,
		dense: Boolean,
		min: [Number, Date],
		max: [Number, Date],
		value: {
			type: [Number, Date, String]
		},
		dialogWidth: {
			type: Number,
			default: 300
		},
		width: {
			default: "100%"
		},
		returnValueType: {
			default: "date"
		},
		barDateField: {
			type: Boolean,
			default: false
		}
	},
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isDialogOpened: false,
			internalFormattedRowValue: "",
			internalVuetifyFormattedRowDate: "",
			masks
		};
	},
	computed: {
		defaultRules() {
			const rules = [
				prepareMaxDateRule({ getMax: () => this.max, format: dateFormat }),
				prepareMinDateRule({ getMin: () => this.min, format: dateFormat })
			];
			if(this.required) rules.push(requiredRule());
			return rules;
		},
		vuetifyFormattedMinRowDate() {
			return this.min && format(this.min, isoDateFormat);
		},
		vuetifyFormattedMaxRowDate() {
			return this.max && format(this.max, isoDateFormat);
		},
		dialogSelectedInternalValue() {
			if(this.internalVuetifyFormattedRowDate) {
				let dateRow = `${this.internalVuetifyFormattedRowDate}`;
				return parse(dateRow, isoDateFormat, new Date());
			}
		}
	},
	methods: {
		async onChange(value) {
			if(!value || !validate(value))
				this.internalFormattedRowValue = "";
		},
		async updateValue(value) {
			if(this.min && value < removeTime(new Date(this.min)))
				return;

			if(this.max && setMaxTime(new Date(this.max)) < value)
				return;

			// Все еще возможна в будущем проблема с часовыми поясами, когда пришла дата из Москвы на вечер 1го числа
			// а пользователь открыл ее на Дальнем востоке, у него уже 2е число.
			if(!value || !this.value || !isSameDay(value, this.value)) {
				if(this.returnValueType === "date")
					this.$emit("update:value", value);
				if(this.returnValueType === "number")
					this.$emit("update:value", value ? value.getTime() : 0);

				if(!value) {
					// временное решение для бага с v-mask
					await this.$nextTick();
					this.internalFormattedRowValue = "";
				}
			}
		},
		openDialog() {
			if(!this.$refs.input.isDisabled)
				this.isDialogOpened = true;
		},
		closeDialog() {
			this.isDialogOpened = false;

			if(this.dialogSelectedInternalValue) {
				this.internalFormattedRowValue = format(this.dialogSelectedInternalValue, dateFormat);
				this.updateValue(this.dialogSelectedInternalValue);
			}
		}
	},
	mounted() {
		this.onChange(this.internalFormattedRowValue);
	},
	watch: {
		internalFormattedRowValue(value) {
			if(!value || validate(value)) {
				this.updateValue(value ? parse(value, dateFormat, new Date()) : null);
			}
		},
		value: {
			handler(value) {
				this.internalFormattedRowValue = value ? format(value, dateFormat) : "";
				this.internalVuetifyFormattedRowDate = value ? format(value, isoDateFormat) : "";
			},
			immediate: true
		}
	}
};
</script>
